var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_delivery_order") } },
    [
      _c(
        "a-form-model",
        {
          attrs: {
            model: _vm.form,
            "label-align": "left",
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.labelCol
          }
        },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16], type: "flex" } },
            [
              _c(
                "a-col",
                { attrs: { sm: 24, md: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_customer_name") } },
                    [
                      _c("CSelectCustomer", {
                        model: {
                          value: _vm.form.customerName,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "customerName", $$v)
                          },
                          expression: "form.customerName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_customer_po_number") } },
                    [
                      _c("a-input", {
                        attrs: {
                          "allow-clear": "",
                          placeholder: _vm.$t("lbl_type_here")
                        },
                        model: {
                          value: _vm.form.customerPoNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "customerPoNumber", $$v)
                          },
                          expression: "form.customerPoNumber"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_delivery_order_number") } },
                    [
                      _c("a-input", {
                        attrs: {
                          "allow-clear": "",
                          placeholder: _vm.$t("lbl_type_here")
                        },
                        model: {
                          value: _vm.form.deliveryOrderNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "deliveryOrderNumber", $$v)
                          },
                          expression: "form.deliveryOrderNumber"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_branch") } },
                    [
                      _c("CSelectBranch", {
                        model: {
                          value: _vm.form.branch,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "branch", $$v)
                          },
                          expression: "form.branch"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { sm: 24, md: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_order_date") } },
                    [
                      _c("a-range-picker", {
                        attrs: {
                          format: _vm.DEFAULT_DATE_FORMAT,
                          placeholder: [
                            _vm.$t("lbl_start_date"),
                            _vm.$t("lbl_end_date")
                          ]
                        },
                        model: {
                          value: _vm.form.orderDate,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "orderDate", $$v)
                          },
                          expression: "form.orderDate"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_eta_date") } },
                    [
                      _c("a-range-picker", {
                        attrs: {
                          format: _vm.DEFAULT_DATE_FORMAT,
                          placeholder: [
                            _vm.$t("lbl_start_date"),
                            _vm.$t("lbl_end_date")
                          ]
                        },
                        model: {
                          value: _vm.form.etaDate,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "etaDate", $$v)
                          },
                          expression: "form.etaDate"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_status") } },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            "allow-clear": "",
                            placeholder: _vm.$t("lbl_choose"),
                            loading: _vm.loading.status,
                            "dropdown-match-select-width": false
                          },
                          model: {
                            value: _vm.form.status,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "status", $$v)
                            },
                            expression: "form.status"
                          }
                        },
                        _vm._l(_vm.optStatus, function(item) {
                          return _c(
                            "a-select-option",
                            { key: item.key, attrs: { value: item.value } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(" " + _vm._s(item.key || "-") + " ")
                                  ]),
                                  _vm._v(" " + _vm._s(item.key || "-") + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16], type: "flex" } },
            [
              _c(
                "a-col",
                { attrs: { sm: 24, md: 12 } },
                [
                  _c(
                    "a-space",
                    [
                      _c("a-button", { on: { click: _vm.handleClear } }, [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_clear")) + " ")
                      ]),
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary", icon: "search" },
                          on: { click: _vm.handleFind }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_find")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { sm: 24, md: 12, align: "end" } },
                [
                  _vm.hasPrivilegeCreate
                    ? _c(
                        "a-button",
                        {
                          attrs: { icon: "plus", type: "primary" },
                          on: { click: _vm.createNew }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_create_new")) + " ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("a-divider"),
      _c(
        "a-row",
        { attrs: { gutter: [16, 16], type: "flex" } },
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-table",
                {
                  attrs: {
                    "data-source": _vm.dtTable,
                    pagination: false,
                    loading: _vm.loading.table,
                    scroll: { x: "calc(1400px + 50%)", y: 500 }
                  }
                },
                [
                  _c(
                    "a-table-column",
                    {
                      key: "deliveryOrderNumber",
                      attrs: { "data-index": "deliveryOrderNumber" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(record.deliveryOrderNumber || "-") +
                                  " "
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_delivery_order_number")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "customerName",
                      attrs: { "data-index": "customerName" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _vm._v(
                                " " + _vm._s(record.customerName || "-") + " "
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_customer_name")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "customerPo",
                      attrs: { "data-index": "customerPo" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _vm._v(
                                " " + _vm._s(record.customerPo || "-") + " "
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_customer_po_number")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "orderDate",
                      attrs: { "data-index": "orderDate" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(_vm._f("date")(record.orderDate)) +
                                  " "
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_order_date")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "etaDate",
                      attrs: { "data-index": "etaDate" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(_vm._f("date")(record.etaDate)) +
                                  " "
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_eta_date")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "branchName",
                      attrs: { "data-index": "branchName" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _vm._v(
                                " " + _vm._s(record.branchName || "-") + " "
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_branch")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "journalNumber",
                      attrs: { "data-index": "journalNumber" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.handleClickColumn(
                                        record.journalNumber
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(record.journalNumber || "-") +
                                      " "
                                  )
                                ]
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_journal_number")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "salesName",
                      attrs: { "data-index": "salesName" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _vm._v(
                                " " + _vm._s(record.salesName || "-") + " "
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_sales_person_name")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "status",
                      attrs: { "data-index": "status" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _vm._v(" " + _vm._s(record.status || "-") + " ")
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_status")))
                      ])
                    ]
                  ),
                  _vm.hasPrivilegeCreate || _vm.hasPrivilegeApprovalDO
                    ? _c(
                        "a-table-column",
                        {
                          key: "action",
                          attrs: {
                            align: "center",
                            fixed: "right",
                            width: 100
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(text, record) {
                                  return [
                                    _c(
                                      "a-dropdown",
                                      {
                                        attrs: {
                                          trigger: ["click"],
                                          placement: "bottomCenter"
                                        }
                                      },
                                      [
                                        _c("a-icon", {
                                          attrs: { type: "more" },
                                          on: {
                                            click: function(e) {
                                              return e.preventDefault()
                                            }
                                          }
                                        }),
                                        _c(
                                          "a-menu",
                                          {
                                            attrs: { slot: "overlay" },
                                            slot: "overlay"
                                          },
                                          [
                                            _vm.hasPrivilegeCreate &&
                                            record.status ===
                                              _vm.DELIVERY_ORDER_STATUS.DRAFT
                                              ? _c(
                                                  "a-menu-item",
                                                  {
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.editDO(
                                                          record
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t("lbl_edit")
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            (_vm.hasPrivilegeCreate ||
                                              _vm.hasPrivilegeApprovalDO) &&
                                            record.status !==
                                              _vm.DELIVERY_ORDER_STATUS.DRAFT
                                              ? _c(
                                                  "a-menu-item",
                                                  {
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.viewDO(
                                                          record
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t("lbl_view")
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            3622880761
                          )
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_action")))]
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: [16, 16], type: "flex" } },
        [
          _c(
            "a-col",
            { attrs: { sm: 24, md: 16 } },
            [
              _c("Pagination", {
                attrs: {
                  total: _vm.dtListDeliveryOrder.totalElements,
                  "page-size-set": _vm.queryParams.limit,
                  "id-pagination": "pagination1"
                },
                on: {
                  "response-pagesize-change": _vm.responsePageSizeChange,
                  "response-currentpage-change": _vm.responseCurrentPageChange
                }
              })
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 24, md: 8, align: "end" } },
            [
              _c("a-tag", { attrs: { color: "#8c8c8c" } }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("lbl_total_found")) +
                    " : " +
                    _vm._s(_vm.dtListDeliveryOrder.totalElements) +
                    " "
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { Api } from "@/models/class/api.class";
import { RequestDeliveryOrderCreate, RequestDeliveryOrderUpdate, RequestQualityControlDelivery, ResponseDeliveryOrderDetail, ResponseListAvailableDeliveryOrderAR, ResponseListDeliveryOrder, ResponseSubmitDeliveryOrder } from "@interface/delivery-order.interface";
import { ResponseDetailSalesOrder } from "@interface/sales-order.interface";
import { ErrorResponseModel, RequestQueryParamsModel } from "@/models/interface/http.interface";
import { HttpClient } from "@/services/http.service";
import { AxiosError } from "axios";

export class DeliveryOrderServices extends HttpClient {
  constructor() {
    super();
  }

  getList(params: RequestQueryParamsModel): Promise<ResponseListDeliveryOrder> {
    return this.get<ResponseListDeliveryOrder>(Api.DeliveryOrder, { params })
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  getListAvailableAR(params: RequestQueryParamsModel): Promise<ResponseListAvailableDeliveryOrderAR> {
    return this.get<ResponseListAvailableDeliveryOrderAR>(`${Api.DeliveryOrder}/available/ar`, { params })
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  getOutstandingBySO(id: string): Promise<ResponseDetailSalesOrder> {
    return this.get<ResponseDetailSalesOrder>(`${Api.SalesOrder}/outstanding/${id}`)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  getListAvailableDeliveryOrder(params: RequestQueryParamsModel): Promise<ResponseListDeliveryOrder> {
    return this.get<ResponseListDeliveryOrder>(Api.AvailableDeliveryOrder, {params})
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
    });
}

  /**
   * create new DO with status DRAFT
   * @param payload payload create DO
   * @returns detail DO
   */
  createDO(payload: RequestDeliveryOrderCreate): Promise<ResponseDeliveryOrderDetail> {
    return this.post<ResponseDeliveryOrderDetail, RequestDeliveryOrderCreate>(Api.DeliveryOrder, payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  updateDO(id: string, payload: RequestDeliveryOrderUpdate): Promise<ResponseDeliveryOrderDetail> {
    return this.put<ResponseDeliveryOrderDetail, RequestDeliveryOrderUpdate>(`${Api.DeliveryOrder}/${id}`, payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  submitDO(id: string): Promise<ResponseSubmitDeliveryOrder> {
    return this.put<ResponseSubmitDeliveryOrder, string>(`${Api.DeliveryOrder}/submit/${id}`)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  getDetail(id: string): Promise<ResponseDeliveryOrderDetail> {
    return this.get<ResponseDeliveryOrderDetail>(`${Api.DeliveryOrder}/${id}`)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  approveDO(id: string): Promise<ResponseSubmitDeliveryOrder> {
    return this.put<ResponseSubmitDeliveryOrder, string>(`${Api.DeliveryOrder}/approve/${id}`)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  printDO(id: string): Promise<ArrayBuffer> {
    return this.get<ArrayBuffer>(`${Api.DeliveryOrder}/print/${id}`, {
      responseType: "arraybuffer"
    })
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  deliveryOrderUpdateQC(id: string, payload: RequestQualityControlDelivery): Promise<ResponseDeliveryOrderDetail> {
    return this.put<ResponseDeliveryOrderDetail, RequestQualityControlDelivery>(`${Api.DeliveryOrder}/qc/${id}`, payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  cancel(id: string): Promise<ResponseDeliveryOrderDetail> {
    return this.put<ResponseDeliveryOrderDetail, RequestQualityControlDelivery>(`${Api.DeliveryOrder}/cancel/${id}`)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  getOutstandingInvoice(id: string): Promise<ResponseDeliveryOrderDetail> {
    return this.get<ResponseDeliveryOrderDetail>(`${Api.DeliveryOrder}/outstanding/${id}`)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

}

export const deliveryOrderService = new DeliveryOrderServices();

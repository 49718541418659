






















































































































































































































































































import { DELIVERY_ORDER_STATUS } from "@/models/enums/delivery-order.enum";
import { Moment } from "moment";
import { DEFAULT_DATE_FORMAT } from "@constant/date.constant";
import Vue from "vue";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { ResponseListMasterType } from "@interface/master.interface";
import { masterTypeService } from "@service/master-type.service";
import MNotificationVue from "@/mixins/MNotification.vue";
import { IOption } from "@interface/common.interface";
import { ResponsePagination } from "@/models/interface/common.interface";
import { ResponseDeliveryOrder, ResponseListDeliveryOrder } from "@interface/delivery-order.interface";
import { deliveryOrderService } from "@service/delivery-order.service";
import { trimSpaceToUnderscore } from "@/helpers/common";
import {generalJournalServices} from "@service/general-journal.service";
import { mapGetters } from "vuex";

interface Row extends ResponseDeliveryOrder {
  key: any;
}

export default Vue.extend({
  name: "DeliveryOrderList",
  components: {
    CSelectCustomer: () => import(/*webpackPrefetch: true*/"@/components/shared/select-customer/CSelectCustomer.vue")
  },
  mixins: [
    MNotificationVue,
  ],
  data() {
    return {
      DEFAULT_DATE_FORMAT,
      DELIVERY_ORDER_STATUS,
      form: {
        customerName: undefined as string | undefined,
        customerPoNumber: "",
        salesOrderNumber: "",
        deliveryOrderNumber: "",
        journalNumber:"",
        branch: undefined as string | undefined,
        orderDate: null as Moment[] | null,
        etaDate: null as Moment[] | null,
        status: undefined as DELIVERY_ORDER_STATUS | undefined,
      },
      optStatus: [] as IOption[],
      loading: {
        status: false,
        table: false,
      },
      dtTable: [] as Row[],
      dtListDeliveryOrder: {} as ResponseListDeliveryOrder,
      queryParams: {
        page: 0,
        limit: 20,
      } as RequestQueryParamsModel,
    };
  },
  computed: {
    ...mapGetters({
      getUserPrivileges: "authStore/GET_USER_PRIVILEGES",
    }),
    hasPrivilegeCreate(): boolean {
      return this.getUserPrivileges.find(x => x.key === "delivery-order" && x.privilege.update && x.privilege.create);
    },
    hasPrivilegeApprovalDO(): boolean {
      return this.getUserPrivileges.find(x => x.key === "approval-delivery-order");
    },
    labelCol() {
      return {
        sm: { span: 24 },
        md: { span: 12 }
      };
    },
  },
  created() {
    this.getListStatus();
    this.getListDO(this.queryParams);
  },
  methods: {
    async handleClickColumn(journalNumber) {
      let data;
      const paramsJournal = {
        page: 0,
        limit: 10,
        search: `name~${journalNumber}`,
        sorts: "createdDate:desc",
      } as RequestQueryParamsModel;
      data = await generalJournalServices.listGeneralJournal(paramsJournal);
      if (data.data.length > 0) {
        this.$confirm({
          title: "You will be redirected to another page, are you sure?",
          onOk: () => {
            this.$router.push(`/generaljournal/journal/detail/${data.data[0].id}`);
          },
          onCancel() {
            return;
          },
        });
      }
    },
    getListMasterType(params: RequestQueryParamsModel): Promise<ResponseListMasterType[]> {
      return masterTypeService.listMaster(params);
    },
    getListDeliveryOrder(params: RequestQueryParamsModel): Promise<ResponseListDeliveryOrder> {
      return deliveryOrderService.getList(params);
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.queryParams.limit = response.size;
      this.queryParams.page = 0;
      this.getListDO(this.queryParams);
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.queryParams.page = response.page - 1;
      this.getListDO(this.queryParams);
    },
    createNew(): void {
      this.$router.push({ name: "sales.transactionsales.deliveryorder.create" });
    },
    editDO(record: Row): void {
      this.$router.push({
        name: "sales.transactionsales.deliveryorder.edit",
        params: { id: record.id }
      });
    },
    viewDO(record: Row): void {
      this.$router.push({
        name: "sales.transactionsales.deliveryorder.detail",
        params: { id: record.id }
      });
    },
    handleClear(): void {
      this.form = {
        customerName: undefined,
        customerPoNumber: "",
        salesOrderNumber: "",
        deliveryOrderNumber: "",
        journalNumber: "",
        branch: undefined,
        orderDate: null,
        etaDate: null,
        status: undefined,
      };
    },
    async getListStatus(): Promise<void> {
      try {
        this.loading.status = true;
        const res = await this.getListMasterType({name: "DELIVERY_ORDER_STATUS"});
        this.optStatus = res.map(x => ({ key: x.value, value: x.value }));
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading.status = false;
      }
    },
    async getListDO(params: RequestQueryParamsModel): Promise<void> {
      try {
        this.loading.table = true;
        const res = await this.getListDeliveryOrder(params);
        this.dtListDeliveryOrder = res;
        this.mapTable();
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading.table = false;
      }
    },
    mapTable(): void {
      this.dtTable = this.dtListDeliveryOrder.data.map(x => ({
        key: x.id,
        ...x
      }));
    },
    handleFind(): void {
      const {
        customerName,
        customerPoNumber,
        salesOrderNumber,
        deliveryOrderNumber,
        branch,
        orderDate,
        etaDate,
        status,
      } = this.form;
      const searchBy: string[] = [];
      if (customerName) searchBy.push(`customer.secureId~${customerName}`);
      if (customerPoNumber) searchBy.push(`customerPo~*${customerPoNumber}*`);
      if (salesOrderNumber) searchBy.push(`salesOrderNumber~*${salesOrderNumber}*`);
      if (deliveryOrderNumber) searchBy.push(`deliveryOrderNumber~*${deliveryOrderNumber}*`);
      if (branch) searchBy.push(`branch.secureId~${branch}`);
      if (status) searchBy.push(`status~${trimSpaceToUnderscore(status)}`);

      if (orderDate && orderDate.length) {
        const [start, end] = orderDate;
        start.set({ hour: 0, minute: 0, second: 0});
        end.set({ hour: 23, minute: 59, second: 59});
        searchBy.push(`orderDate>=${start.format()}_AND_orderDate<=${end.format()}`);
      }

      if (etaDate && etaDate.length) {
        const [start, end] = etaDate;
        start.set({ hour: 0, minute: 0, second: 0});
        end.set({ hour: 23, minute: 59, second: 59});
        searchBy.push(`etaDate>=${start.format()}_AND_etaDate<=${end.format()}`);
      }

      this.queryParams.search = searchBy.join("_AND_");
      this.getListDO(this.queryParams);
    }
  },
});

export enum DELIVERY_ORDER_STATUS {
  BILLED = "Billed",
  DELIVERED = "Delivered",
  NEED_APPROVAL = "Wait For Approval",
  PARTIAL_INVOICING = "Partial Invoicing",
  CANCELLED = "Cancelled",
  DRAFT = "Draft",
  INVOICING = "Invoicing",
  PAID = "Paid",
  PARTIAL_PAID = "Partial Paid",
  FULL_PAID = "Fully Paid",
  UNBILLED = "Unbilled",
  RETURNED = "Returned",
  UNPAID = "Unpaid",
}